// import { initializeApp } from 'firebase/compat/app';
import 'firebase/auth';
import 'firebase/database';
import  firebase from 'firebase/app';

const firebaseConfig =     {
	apiKey: "AIzaSyAhcG705cJfvSJ3yC489xP7_KWz_d95R3k",
	authDomain: "bw-dienstplan-ceb87.firebaseapp.com",
	databaseURL: "https://bw-dienstplan-ceb87-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "bw-dienstplan-ceb87",
	storageBucket: "bw-dienstplan-ceb87.appspot.com",
	messagingSenderId: "180183788651",
	appId: "1:180183788651:web:1efae8d0c228d126aa952e",
	measurementId: "G-QDQ8Q53G7B"
};


firebase.initializeApp(firebaseConfig);

export const db = firebase.database()
export const eventRef = db.ref("2025-q2/events")
export const compRef = db.ref("competences")


